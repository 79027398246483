import React from 'react';
import { Link } from 'gatsby';
import { DesktopNavItemProps } from 'gatsby-theme-husky/src/components/Navigation/models';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';

export const NavItem = ({ item, toggleIsOpen, isOpen, closeMenu }: DesktopNavItemProps) => {
  const hasSublevel = item.properties.subItem?.length;
  const navItemClassNames = classNames('desktop-nav-item', {
    [`desktop-nav-item--opened`]: isOpen,
  });
  const itemWithSublevelClassNames = classNames('desktop-nav-item__item', {
    [`desktop-nav-item__item--with-sublevel`]: hasSublevel,
  });

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleIsOpen(true);
    }
  };

  return (
    <li
      className={navItemClassNames}
      onMouseEnter={() => toggleIsOpen()}
      onMouseLeave={() => toggleIsOpen()}
      data-testid="desktop-nav-item"
    >
      <div className={itemWithSublevelClassNames}>
        {item.properties.link ? (
          <Link to={item.properties.link[0].url} onFocus={closeMenu}>
            {item.properties.name}
          </Link>
        ) : (
          <button
            onKeyDown={handleKeyPress}
            onFocus={closeMenu}
            type="button"
            data-testid={`desktop-nav-item-button-${kebabCase(item.properties.name)}`}
          >
            {item.properties.name}
          </button>
        )}
      </div>

      {hasSublevel ? (
        <ul className="desktop-nav-item__sublevel">
          {item.properties.subItem?.map((navSubItem) => (
            <li
              data-testid="desktop-nav-subitem"
              className="desktop-nav-item__subitem"
              key={navSubItem.properties.name}
            >
              <Link to={navSubItem.properties.link && navSubItem.properties.link[0].url}>
                {navSubItem.properties.name}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default NavItem;
